<template>
  <div>
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="head">
          <BrandLogo :width="170" :height="170" :image="partner.image" />
          <div class="right-area" v-if="headerData">
            <VueHeadline level="2" weightLevel="6" color="grey-40" class="headline-text">{{
              headerData.title
            }}</VueHeadline>
            <VueText sizeLevel="3" color="grey-30" class="text">
              <div>{{ headerData.description }}</div>
            </VueText>
            <div class="people-wrapper">
              <VueGridContainer>
                <VueGridRow class="grid-row">
                  <VueGridCol>
                    <BrandMeetPerson
                      v-if="headerData.salesManagerInfo"
                      :hrefCall="headerData.salesManagerInfo.tep"
                      :person="headerData.salesManagerInfo"
                      :cornerIcon="icons.phoneAlt"
                    />
                  </VueGridCol>
                  <VueGridCol>
                    <BrandMeetPerson
                      v-if="headerData.businessDeveleopmentManagerInfo"
                      :hrefCall="headerData.businessDeveleopmentManagerInfo.tep"
                      :person="headerData.businessDeveleopmentManagerInfo"
                      :cornerIcon="icons.phoneAlt"
                    />
                  </VueGridCol>
                </VueGridRow>
              </VueGridContainer>
            </div>
          </div>
        </div>

        <div class="tabs">
          <div
            :class="activeTab == 'visitDays' ? 'active-title' : ''"
            @click="setActiveTab('visitDays')"
          >
            <VueText
              :color="activeTab === 'visitDays' ? 'currentColor' : 'grey-30'"
              sizeLevel="5"
              weightLevel="3"
              >ZİYARET GÜNLERİM</VueText
            >
          </div>
          <div
            :class="activeTab == 'orderDays' ? 'active-title' : ''"
            @click="setActiveTab('orderDays')"
          >
            <VueText
              :color="activeTab === 'orderDays' ? 'currentColor' : 'grey-30'"
              sizeLevel="5"
              weightLevel="3"
              >ÖN SİPARİŞ GÜNLERİM</VueText
            >
          </div>
        </div>
        <hr class="bottom" />

        <div class="days-table">
          <div class="day" v-for="(day, index) in days" :key="index">
            <div class="day-name">{{ day }}</div>
            <div class="day-check" v-for="(partner, i) in partners" :key="i">
              <VueIcon
                v-if="isChecked(day, partner)"
                :iconName="constants.icons.iconChecked.name"
                :width="constants.icons.iconChecked.width"
                :height="constants.icons.iconChecked.height"
              />
            </div>
          </div>
        </div>
        <div class="items-wrapper">
          <router-link
            class="list-button"
            :to="{ path: productAndPriceListButton.path }"
            @click.native="dataLayer()"
          >
            <VueIcon
              class="icon-style"
              iconColor="white"
              :iconName="productAndPriceListButton.icon.name"
              :width="productAndPriceListButton.icon.width"
              :height="productAndPriceListButton.icon.height"
            />
            <span>{{ productAndPriceListButton.text }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <VueHeadline class="products-headline" level="1" weightLevel="3">Kategoriler</VueHeadline>
        <div class="products-wrapper">
          <BrandProduct
            v-for="category in categories"
            :key="category.id"
            :productName="category.name"
            :productPath="`ulker/${slugify(category.name)}/${category.id}`"
            :productImageSrc="category.image"
          ></BrandProduct>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueGridContainer from '@/components/shared/VueGridContainer/VueGridContainer.vue';
import VueGridRow from '@/components/shared/VueGridRow/VueGridRow.vue';
import VueGridCol from '@/components/shared/VueGridCol/VueGridCol.vue';
import BrandMeetPerson from '@/components/brand/BrandMeetPerson/BrandMeetPerson.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandProduct from '@/components/brand/BrandProduct/BrandProduct.vue';
import { Pladis } from '@/services/Api/index.js';
import { User } from '@/services/Api/index';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import { getPartnerImage } from '@/utils/partnerImageUtils.js';
import { BUSINESS_PARTNERS } from '@/constants/businessPartners.constants';
import { slugify } from '@/utils/stringUtils';
import { DAYS } from '@/constants/days.constants';

export default {
  name: 'Home',
  components: {
    BrandLogo,
    VueHeadline,
    VueText,
    VueGridContainer,
    VueGridRow,
    VueGridCol,
    BrandMeetPerson,
    VueIcon,
    BrandProduct,
  },
  mixins: [gtmUtils],
  data() {
    return {
      partner: {
        code: 'PLADIS',
        image: getPartnerImage('ulker'),
      },
      headContent: 'Ülker',
      headerData: null,
      productAndPriceListButton: {
        id: 0,
        text: 'Güncel Ürün ve Fiyat Listesi',
        icon: { name: 'IconPriceList', width: 22, height: 26 },
        path: 'ulker/product-price-list',
      },
      activeTab: 'visitDays',
      categories: [],
      days: DAYS,
      partners: [],
    };
  },
  created() {
    this.getRouteDays();
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: ICON_VARIABLES,
      };
    },
  },
  mounted() {
    this.getCategories();
    this.getHeader();
  },
  methods: {
    dataLayer() {
      this.pushDataLayerEvent('fiyat_listesi_click', {
        location: 'business-partner',
        partner: 'ÜLKER',
      });
    },
    getRouteDays() {
      User.getRouteDays()
        .then(res => {
          if (res.data && res.data.Data) {
            res.data.Data.forEach(f => {
              if (f.partnerName == BUSINESS_PARTNERS.ULKER.partnerName) this.partners = [f];
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCategories() {
      Pladis.getCategories(BUSINESS_PARTNERS.ULKER.partnerCode).then(response => {
        if (response.data && response.data.Data) {
          this.categories = response.data.Data;
        }
      });
    },
    setActiveTab(tab) {
      if (this.activeTab != tab) {
        this.activeTab = tab;
      }
    },
    isChecked(day, partner) {
      if (this.activeTab === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    },
    getHeader() {
      Pladis.getHeader(BUSINESS_PARTNERS.ULKER.partnerCode).then(response => {
        if (response.data && response.data.Data) {
          let data = response.data.Data;
          this.headerData = data;
          if (data.salesManagerInfo) {
            this.headerData.salesManagerInfo = {
              name: data.salesManagerInfo.name
                ? data.salesManagerInfo.name.trim().toLocaleUpperCase('tr-TR')
                : '-',
              title: 'Satış Plasiyeri',
              tep: data.salesManagerInfo.phone_Number
                ? `tel:+90${data.salesManagerInfo.phone_Number.trim()}`
                : '',
            };
          }
          if (data.businessDeveleopmentManagerInfo) {
            this.headerData.businessDeveleopmentManagerInfo = {
              name: data.businessDeveleopmentManagerInfo.name
                ? data.businessDeveleopmentManagerInfo.name.trim().toLocaleUpperCase('tr-TR')
                : '-',
              title: 'Satış Şefi',
              tep: data.businessDeveleopmentManagerInfo.phone_Number
                ? `tel:+90${data.businessDeveleopmentManagerInfo.phone_Number.trim()}`
                : '',
            };
          }
        }
      });
    },
    slugify,
  },
};
</script>

<style scoped lang="scss">
.content-row {
  height: unset;
  padding-left: 20px;
  padding-bottom: 50px;
}
.gray-bg {
  background: #f8f9f9;
  margin-bottom: 60px;
}
.head {
  display: flex;
  padding: 40px 0 50px 0;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
.right-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 18px;
  width: 100%;

  .headline-text {
    margin-bottom: palette-space-level(10);
  }
  .text {
    line-height: 1.27;
    padding-right: palette-space-level(10);
  }

  .link-holder {
    display: flex;
    flex-direction: row;
    align-items: space-between;

    .left-link {
      padding-right: palette-space-level(30);
    }
    .left-link,
    .right-link {
      padding-top: palette-space-level(10);
      line-height: 1.27;
      text-decoration: underline;
    }
  }
}
.people-wrapper {
  margin-top: 18px;
}
.list-button {
  margin-top: 40px;
  display: block;
  height: 55px;
  width: max-content;
  line-height: 55px;
  padding: 0 28px;
  text-align: center;
  border-radius: palette-radius-level('15');
  background-image: linear-gradient(to left, #d20051, #ffa300);
  margin-right: 15px;
  text-decoration: none;
  color: #ffffff;
  a {
    color: #ffffff;
    text-decoration: none;
  }
}
.icon-style {
  display: inline-block;
  position: relative;
  left: -10px;
}
.grid-row {
  gap: 20px;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 21px;
  grid-row-gap: 40px;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
  /deep/ .product-img {
    padding: 20px 0;
  }
}
.products-headline {
  padding: 0 0 30px 0;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
/deep/ .product {
  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  &-img {
    width: 100%;
    height: 235px;
    padding: 37px 15px;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('2');

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-name {
    padding: palette-space-level('10') 0;
    text-align: center;
    font-size: 24px;
  }
}
.tabs {
  display: flex;
  z-index: 5;
  & > div {
    padding-bottom: palette-space-level(10);
    margin-right: palette-space-level(40);
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      border-radius: palette-radius('radius-11');
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -3px;
      left: 0;
      background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
      opacity: 0;
      transition: all 0.3s ease;
    }
    &.active-title {
      position: relative;
      &::after {
        opacity: 1;
      }
    }
  }
}
.days-table {
  margin-top: palette-space-level(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
}
.day {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  &-name {
    background-color: palette-color-level('grey', 10);
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
.items-wrapper {
  margin-top: palette-space-level(50);
  text-align: -webkit-center;
}
</style>
